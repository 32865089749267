import React from "react";
import ribbon from '../assets/ribbon-main.svg'
import photo1 from '../assets/photo1-main.png'
import photo2 from '../assets/photo2-main.png'
import photo3 from '../assets/photo3-main.png'
import photo4 from '../assets/photo4-main.png'
import sign from '../assets/sign-main.svg'


function Main() {
   return (
      <div class="container-main">
         <div class="main-content">
            <div class="main-text">
               <h1>Профессиональная&nbsp;уборка в&nbsp;Красноярске</h1>
               <p>Освободим ваше время от домашних рутинных дел, пока вы занимаетесь&nbsp;чем-то важным</p>
               <img src={sign} alt="" />
            </div>
            <button class="main-btn">
               <h4>Рассчитать стоимость</h4>
            </button>
            <img id="ribbon__sign" src={ribbon} alt="" />
            <p id="discount__sign" class="accent-primary">Скидка 20%<br></br>на&nbsp;первый&nbsp;заказ</p>
         </div>
         <div class="main-photo">
            <div>
               <img src={photo1} alt="" />
               <img src={photo2} alt="" />
            </div>
            <div>
               <img src={photo3} alt="" />
               <img src={photo4} alt="" />
            </div>
         </div>
      </div>
   )
}

export default Main