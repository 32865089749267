import React from "react";
import stars from '../assets/stars-advantages.svg'
import icon1 from '../assets/icon1-advantages.svg'
import icon2 from '../assets/icon2-advantages.svg'
import icon3 from '../assets/icon3-advantages.svg'
import icon4 from '../assets/icon4-advantages.svg'
import photo from '../assets/photo-advantages.png'
import AdvantageItem from "./UI/AdvantageItem";

function Advantages() {
   return (
      <div class="container-advantages">
         <div class="advantages-header">Клиндом - про чистоту и заботу вашего дома</div>
         <div class="advantages-area">
            <img id="advantages__sign" src={stars} alt="" />
            <img src={photo} alt="" />
            <div class="advantages-list">
               <div class="advantages-row">
                  <AdvantageItem
                     icon={icon1}
                     header='Качество'
                     text={<p>Мы&nbsp;тщательно отбираем и&nbsp;обучаем сотрудников</p>}
                  />
                  <AdvantageItem
                     icon={icon2}
                     header='Надежность'
                     text={<p>Компенсация или ремонт, если&nbsp;что-то пойдет не так</p>}
                  />
               </div>
               <div class="advantages-row">
                  <AdvantageItem
                     icon={icon3}
                     header='Фиксированная цена'
                     text={<p>Не меняем цену после проведения услуги</p>}
                  />
                  <AdvantageItem
                     icon={icon4}
                     header='Акции'
                     text={<p>Для постоянных клиентов уборки дешевле</p>}
                  />
               </div>
            </div>
         </div>
      </div>
   )
}

export default Advantages