import React from "react";
import logo from '../assets/logo-header.svg'
import whatsapp from '../assets/whatsapp-footer.png'
import telegram from '../assets/tg-footer.png'
import vk from '../assets/vk-footer.png'

function Footer() {
   return (
      <div class="container-footer">
         <div className="footer-area">
            <img id='footer-logo' src={logo} alt="logo" />
            <div className="footer-info">
               <div className="footer-services-container">
                  <p>Услуги</p>
                  <div className="footer-services">
                     <div className="footer-services-item">Уборка</div>
                     <div className="footer-services-item">Химчистка</div>
                     <div className="footer-services-item">Мойка окон и фасадов</div>
                  </div>
               </div>
               <div className="footer-services-container">
                  <p>Способы связи</p>
                  <div className="footer-icon">
                     <img src={whatsapp} alt="" />
                     <img src={telegram} alt="" />
                     <img src={vk} alt="" />
                  </div>
                  <div>+7 901 646-29-08</div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Footer