import React from "react";
import clock_icon from '../../assets/clock-services.svg'
import cleaners_icon from '../../assets/cleaners-services.svg'
import arrow from '../../assets/arrow-services.svg'

function ServiceItem({number, name, text, hours, cleaners, old_price, new_price}) {
   return (
      <div class="services-item">
         <div class="services-info">
            <div class="services-item-head">
               <div class="services-number accent-primary">
                  <h3>{number}</h3>
               </div>
               <div class="services-name accent-primary">{name}</div>
            </div>
            <div class="services-description">{text}</div>
            <div class="services-stats">
               <div class="services-stats-item">
                  <img src={clock_icon} alt="" />
                  <div class="services-time">
                     <p>{hours} часов</p>
                  </div>
               </div>
               <div class="services-stats-item">
                  <img src={cleaners_icon} alt="" />
                  <div class="services-cleaner">
                     <p>{cleaners} клинера</p>
                  </div>
               </div>
            </div>
            <div class="services-full-info accent-primary">
               <p>Полный перечень работ</p>
               <img src={arrow} alt="" />
            </div>
         </div>
         <div class="services-price-area">
            <div class="services-price">
               <div class="services-old-price text-secondary">от&nbsp;{old_price}&nbsp;₽</div>
               <div class="services-new-price">от&nbsp;{new_price}&nbsp;₽</div>
            </div>
            <button class="btn"><p>Заказать</p></button>
         </div>
      </div>
   )
}

export default ServiceItem