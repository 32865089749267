import React from "react";
import QuestionItem from "./UI/QuestionItem";

function Questions() {
   return (
      <div class="container-questions">
         <div class="services-header">Остались вопросы?</div>
         <div className="questions-list">
            <QuestionItem
            question='Нужно ли мне быть дома во время уборки?'
            text=''
            />
            <QuestionItem
            question='Не испортите ли вы мне что-нибудь во время чистки?'
            text=''
            />
            <QuestionItem
            question='Можно ли заказывать конкретного специалиста?'
            text=''
            />
         </div>
      </div>
   )
}

export default Questions