import React, { useState } from "react";
import arrow from '../assets/arrow-calculator.svg'

function Calculator() {
   const [typeService, setTypeService] = useState('Поддерживающая');
   const [area, setArea] = useState();

    const services = [
        'Поддерживающая',
        'Генеральная',
        'После\u00A0ремонта'
    ];

    const handleServiceClick = (service) => {
      setTypeService(service);
  };

   function calculate_cost() {
      if (typeService === 'Поддерживающая') {
         if (!area || area < 20) {
            return 1990
         }
         return 100*area
      }
      if (typeService === 'Генеральная') {
         if (!area || area < 20) {
            return 3990
         }
         return 150*area
      }
      if (typeService === 'После\u00A0ремонта') {
         if (!area || area < 20) {
            return 7990
         }
         return 200*area
      }
   };

   function handler_change_area(value) {
      if (value < 999 && value > 0 || !value) {
         setArea(value)
      }
   }

   return (
      <div class="container-calculator">
         <div id='calculator-anchor'></div>
         <div class="services-header">Калькулятор цен</div>
         <div class="calculator-area">
            <div class="calculator-select">
               <div class="calculator-topic">
                  <div class="calculator-topic-header">Выберите тип уборки</div>
                  <div className="calculator-type-selection">
                        {services.map((service) => (
                           <div
                              key={service}
                              className={'calculator-type text-secondary'.concat(' ', typeService === service ? 'active' : '')}
                              onClick={() => handleServiceClick(service)}
                           >
                              <h4>{service}</h4>
                           </div>
                        ))}
                  </div>
               </div>
               <div class="calculator-topic">
                  <div class="calculator-topic-header">Укажите площадь помещения</div>
                  <div className="calculator-input-area">
                     <input id="calculator-input-area" placeholder="40" type="number" value={area} onChange={event => handler_change_area(event.target.value)} />
                     <label htmlFor="calculator-input-area" ><h4>м²</h4></label>
                  </div>
               </div>
               <div class="calculator-topic">
                  <div class="calculator-topic-header drop">
                     Дополнительные услуги
                     <img src={arrow} alt="" />
                  </div>
                  <div class="calculator-service-selection">
                     <div class="calculator-service">
                        <div class="calculator-service-info">
                           <p>Мойка духовки изнутри</p>
                           <p>500 ₽</p>
                        </div>
                        <div class="calculator-service-count-container">
                           <div class="calculator-service-count-btn">
                              <h4 class="accent-primary">-</h4>
                           </div>
                           <p>1</p>
                           <div class="calculator-service-count-btn accent-primary">
                              <h4 class="accent-primary">+</h4>
                           </div>
                        </div>
                     </div>
                     <div class="calculator-service">
                        <div class="calculator-service-info">
                           <p>Мытье холодильника внутри</p>
                           <p>800 ₽</p>
                        </div>
                        <div class="calculator-service-count-container">
                           <div class="calculator-service-count-btn">
                              <h4 class="accent-primary">-</h4>
                           </div>
                           <p>1</p>
                           <div class="calculator-service-count-btn accent-primary">
                              <h4 class="accent-primary">+</h4>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="calculator-result-container">
               <div class="calculator-topic-header">Итого</div>
               <div class="calculator-price">{calculate_cost()} ₽</div>
               <div class="calculator-price-description">Точная стоимость уточняется у менеджера</div>
               <div class="calculator-form">
                  <input class="calculator-input" placeholder="Как вас зовут" type="text" />
                  <input class="calculator-input" placeholder="Ваш номер телефона" type="text" />
               </div>
               <button class="calculator-btn">
                  <h4>Заказать уборку</h4>
               </button>
               <div class="calculator-btn-description text-secondary">Оператор свяжется с вами в течении 15 минут</div>
               </div>
            </div>
         </div>
   )
}

export default Calculator