import React from "react";

function ReviewItem({photo, name, rating, service, text}) {
   return (
      <div class="reviews-item-container">
         <img class="reviews-photo" src={photo} alt="" />
         <div class="reviews-item-info-container">
            <div class="reviews-item-info">
               <div class="reviews-item-header">
                  <h4>{name}</h4>
                  <img src={rating} alt="" />
               </div>
               <p>Услуга: {service}</p>
            </div>
            <p class="text-secondary">{text}</p>
         </div>
      </div>
   )
}

export default ReviewItem