import React from "react";
import arrow_down from '../../assets/arrow-down-questions.svg'
// import arrow_top from '../../assets/arrow-top-questions.svg'


function QuestionItem({question, text}) {
   return (
      <div class="questions-item">
         <div className="services-new-price">{question}</div>
         <img src={arrow_down} alt="" />
      </div>
   )
}

export default QuestionItem