import React from "react";
import ServiceItem from "./UI/ServiceItem";

function Services() {
   return (
      <div class="container-services">
         <div id='services-anchor'></div>
         <div class="services-header">Тарифы уборок</div>
         <img id="services__sign" src="" alt="" />
         <p id="services__textsign" class="accent-primary">Выбирайте подходящий<br></br>для себя тариф</p>
         <div class="services-list">
            <ServiceItem 
               number='1'
               name='Поддерживающая'
               text={<p>Подходит для&nbsp;<b>семьи</b> с&nbsp;детьми и&nbsp;домашними животными&nbsp;или занятого <b>профессионала</b>, у&nbsp;которых нет&nbsp;времени на&nbsp;регулярные уборки.</p>}
               hours='4-5'
               cleaners='1-2'
               old_price='3990'
               new_price='1990'
            />
            <ServiceItem 
               number='2'
               name='Генеральная'
               text={<p>Подходит для&nbsp;тех, кто&nbsp;<b>переехал</b> в&nbsp;новую квартиру или&nbsp;готовится к&nbsp;<b>празднику</b> и&nbsp;нуждается в&nbsp;тщательной уборке</p>}
               hours='5-7'
               cleaners='2-4'
               old_price='5990'
               new_price='3990'
            />
            <ServiceItem 
               number='3'
               name='После&nbsp;ремонта'
               text={<p>Подходит для&nbsp;тех, кто&nbsp;только закончил <b>ремонт</b>, и&nbsp;нуждается в&nbsp;удалении остатков материалов и&nbsp;загрязнений</p>}
               hours='4-5'
               cleaners='1-2'
               old_price='9990'
               new_price='7990'
            />
         </div>
      </div>
   )
}

export default Services