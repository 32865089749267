import React from "react";
import sign from '../assets/sign-form.svg'

function Form() {
   return (
      <div class="container-form">
         <div className="form-info">
            <div className="services-header">Закажите бесплатную<br></br>консультацию с менеджером</div>
            <h4 className="text-secondary">Подробно ответим на&nbsp;все&nbsp;ваши&nbsp;вопросы, проинформируем по&nbsp;стоимости и подберем удобную дату и время</h4>
            <img src={sign} alt="" />
         </div>
         <div className="form-request">
            <div class="calculator-form">
               <input class="calculator-input" placeholder="Как вас зовут" type="text" />
               <input class="calculator-input" placeholder="Ваш номер телефона" type="text" />
            </div>
            <button class="calculator-btn">
               <h4>Отправить заявку</h4>
            </button>
            <div class="calculator-btn-description text-secondary">Оператор свяжется с вами в течении 15 минут</div>
         </div>
      </div>
   )
}

export default Form