import React from "react";
import stars from '../assets/stars-about.svg'

function About() {
   return (
      <div class="container-about">
         <div class="about-info">
            <img id="stars__sign" src={stars} alt="" />
            <div class="about-item">
               <div class="accent-primary">2000 заказов</div>
               <p class="text-secondary">выполненных</p>
            </div>
            <div class="about-item">
               <div class="accent-primary">2 года</div>
               <p class="text-secondary">на рынке клининга</p>
            </div>
            <div class="about-item">
               <div class="accent-primary">4.9 оценка</div>
               <p class="text-secondary">в среднем за услугу</p>
            </div>
         </div>
      </div>
   )
}

export default About