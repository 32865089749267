import React from "react";
import photo1 from '../assets/photo1-reviews.png'
import photo2 from '../assets/photo2-reviews.png'
import arrow_left from '../assets/arrow-left-reviews.svg'
import arrow_right from '../assets/arrow-right-reviews.svg'
import rating from '../assets/rating-reviews.svg'
import ReviewItem from "./UI/ReviewItem";

function Reviews() {
   return (
      <div id='reviews' class="container-reviews">
         <div id='reviews-anchor'></div>
         <div class="services-header">Отзывы наших клиентов</div>
         <p id="reviews__sign" class="accent-primary">Нам доверяют более 2000 человек</p>
         <div class="reviews-list-container">
            <img class="reviews-arrow" src={arrow_left} alt="" />
            <div class="reviews-list">
               <ReviewItem
               photo={photo1}
               name='Татьяна С.'
               rating={rating}
               service='генеральная уборка 60 м²'
               text='Ребята идеально везде помыли, во все углы залезли. Наконец чистая раковина. Спасибо большое!'
               />
               <ReviewItem
               photo={photo2}
               name='Анастасия А.'
               rating={rating}
               service='уборка после ремонта'
               text='Заехали в новую съемную квартиру, после старых жильцов надо было намыть квартиру. Сотрудники вынесли весь мусор, оттерли все пятна. Теперь приятно жить в чистой квартире, спасибо вам!'
               />
               <ReviewItem
               photo={photo1}
               name='Татьяна С.'
               rating={rating}
               service='генеральная уборка 60 м²'
               text='Ребята идеально везде помыли, во все углы залезли. Наконец чистая раковина. Спасибо большое!'
               />   
            </div>
            <img class="reviews-arrow" src={arrow_right} alt="" />
         </div>
      </div>
   )
}

export default Reviews