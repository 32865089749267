import React from "react";

function AdvantageItem({icon, header, text}) {
   return (
      <div class="advantages-item">
         <img src={icon} alt="" />
         <div class="advantages-info">
            <div class="advantages-item-header">{header}</div>
            <div class="advantages-item-description text-secondary">{text}</div>
         </div>
      </div>
   )
}

export default AdvantageItem