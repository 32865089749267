import './App.css';
import React from 'react'
import Header from './components/Header'
import Main from './components/Main';
import About from './components/About';
import Services from './components/Services';
import Advantages from './components/Advantages';
import Calculator from './components/Calculator';
import Reviews from './components/Reviews';
import Questions from './components/Questions';
import Form from './components/Form';
import Footer from './components/Footer';

function App() {
  return (
    <div>
      <div className="container">
        <Header />
        <Main />
        <About />
        <Services />
        <Advantages />
        <Calculator />
        <Reviews />
        <Questions />
        <Form />
        <Footer />
      </div>
    </div>
  );
}

export default App;
