import React from "react";
import logo from '../assets/logo-header.svg'
import arrow from '../assets/drop-arrow-header.svg'
import phone from '../assets/phone-header.svg'

function Header() {
   return (
      <div class="container-header">
         <img src={logo} alt="logo" />
         <div class="header-navigation text-secondary">
            <div class="dropdown">
               <h4>Услуги</h4>
               <img src={arrow} alt="" />
            </div>
            <h4><a href="#services-anchor">Тарифы</a></h4>
            <h4><a href="#calculator-anchor">Калькулятор цен</a></h4>
            <h4><a href="#reviews-anchor">Отзывы</a></h4>
         </div>
         <div class="header-phone">
            <img src={phone} alt="" />
            <div class="accent-primary">+7 901 646-29-08</div>
         </div>
      </div>
   )
}

export default Header